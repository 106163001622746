export interface PostIKHDocumentPreUploadResponse {
  metadata: { [key: string]: string };
  correlationID: string;
}

export interface PostIKHDocumentMetadata {
  title?: string;
  account?: string;
  opportunity_lead?: string;
  opportunity_id?: string;
  capability_unit: string;
  capability_unit_l1: string;
  industry: string;
  created_date: string;
  sec_status: string;
  asset_categories: string;
}

export type UploadAssetCategory = 'Proposals' | 'Credentials' | 'Deliverables' | 'Project Summary' |
  'Capgemini Studies' | 'Invent Approaches or Methods' | 'Training' | 'Marketing and Offers' | 'Knowledge Nugget';

export interface PostIkhDocumentUploadRequest {
  correlation_id: string;
  filename: string;
  metadata: PostIKHDocumentMetadata;
  asset_type: AssetType;
  upload_asset_category: UploadAssetCategory
}

export interface PostIKHDocumentPreUploadLinkRequest {
  link: string;
}

export interface IKHMetadateValue {
  value: string;
}

export interface IKHMetadataCapabilityUnitL1Value {
  value: string;
  capability_unit: string;
}

export interface GetIkhDocumentMetadataResponse {
  industries: IKHMetadateValue[];
  capability_units: IKHMetadateValue[];
  capability_unit_l1: IKHMetadataCapabilityUnitL1Value[];
}

export enum AssetType {
  ProjectAsset = "Project asset",
  CompanyAsset = "Company asset"
}

export interface PatchIkhDocumentContactPerson {
  contact_persons: string[];
}

export interface PatchIkhDocumentRequest {
  summary?: string;
}
