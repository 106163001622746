<div class="container">
  <div class="sub-container" (click)="switchValue(true)">
    {{leftValue}}
  </div>
  <div class="sub-container" (click)="switchValue(false)">
    {{rightValue}}
  </div>
  <div class="over-container sub-container" [ngStyle]="{left: displayValue ? 0 : '150px'}">
    <span *ngIf="value === displayValue">{{value ? leftValue : rightValue}}</span>
  </div>
</div>
