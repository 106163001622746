export const roles: {[role: string]: {mailing_lists: string[], features: string[]}} =
{
  "ADMIN": {
    "features": [
      "ADMIN"
    ],
    "mailing_lists": [
      "c8991262-e2bb-4d80-ac47-9cedd33e38f5"
    ]
  },
  "ALL_ACCESS": {
    "features": [
      "UPLOAD",
      "DOC_FINDER",
      "SLIDE_FINDER",
      "SECURE_GPT",
      "RFP_ASSISTANT",
      "EXPERT_FINDER"
    ],
    "mailing_lists": [
      "c8991262-e2bb-4d80-ac47-9cedd33e38f5"
    ]
  }
}
