<div class="container">
  <img class="homepage-background" src="/assets/homepage-background.jpeg" alt="background">
  <div class="content">
    <h1 class="title">
      Unleashing collaborative knowledge with GenAI to enhance the consultant journey
    </h1>
    <div class="subtitle">
      <div class="text body2" *ngIf="accessibleFeatures && accessibleFeatures.length">
        JUST.AI is powered by collabs.
        <br/>
        The more you upload, the more it will be useful.
      </div>
      <div class="text body2" *ngIf="!accessibleFeatures && !pendingCanAccessTool && user">
        It appears you don't have yet access to JustAI
      </div>
      <div [routerLink]="'upload-docs'" *ngIf="accessibleFeatures.includes(FEAT_UPLOAD)">
        <button qm class="secondary reversed large">
          Upload doc <span class="qm-icon-arrow-right"></span>
        </button>
      </div>
    </div>
    <div class="page-access-container">
      <div class="page-access"
           [ngClass]="pageAccess.enabled ? 'page-access-enabled' : 'page-access-disabled'"
           *ngFor="let pageAccess of getPageAccessList(); let i=index"
           (click)="navigateTo(pageAccess.routerLink)">
        <div class="page-access-title-container">
          <div class="page-access-title">
            {{pageAccess.title}}
          </div>
          <div *ngIf="pageAccess.isBeta" class="beta-version-tag">
            Beta Version
          </div>
        </div>
        <div class="text body2 page-access-subtitle">
          {{pageAccess.subtitle}}
        </div>
        <div *ngIf="pageAccess.enabled" class="page-access-call-to-action-container">
          <div class="page-access-call-to-action">
            {{pageAccess.callToAction}}
          </div>
          <div class="page-access-call-to-action-arrow">
            <span class="qm-icon-arrow-right"></span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
