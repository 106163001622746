import {roles} from "../roles";

export interface CountryInfo {
  name: string;
}

export const countries: { [countryCode: string]: CountryInfo } = {
  FR: {
    name: "France",
  },
  US: {
    name: "USA",
  },
  UK: {
    name: "UK",
  },
  DE: {
    name: "Germany",
  },
  NL: {
    name: "Netherlands",
  },
};


export const FEAT_ADMIN = "ADMIN"
export const FEAT_UPLOAD = "UPLOAD"
export const FEAT_DOC_FINDER = "DOC_FINDER"
export const FEAT_SLIDE_FINDER = "SLIDE_FINDER"
export const FEAT_SECURE_GPT = "SECURE_GPT"
export const FEAT_RFP_ASSISTANT = "RFP_ASSISTANT"
export const FEAT_EXPERT_FINDER = "EXPERT_FINDER"

export const features = [
  FEAT_ADMIN,
  FEAT_UPLOAD,
  FEAT_DOC_FINDER,
  FEAT_SLIDE_FINDER,
  FEAT_SECURE_GPT,
  FEAT_RFP_ASSISTANT,
  FEAT_EXPERT_FINDER,
]
