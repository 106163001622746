import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {fetchUser, setTeamsGroupIds, setUser} from "./user.actions";
import {AuthService} from "@quantmetry/api-services";
import {UserType} from "./user.reducer";


@Injectable()
export class UserEffects {

  fetchUserEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUser.type),
      mergeMap(() => this.authService.getUserInformations()
        .pipe(map(user => {
          const newUser: UserType = {
            ...user,
            countryCode: ""
          };
          if(user.officeLocation && user.officeLocation.length){
            newUser.countryCode = user.officeLocation.split("-")[0].trim();
          }
          return setUser({user: newUser});
        }))
      )
    )
  );


  fetchUserGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUser.type),
      mergeMap(() => this.authService.getUserGroupIds()
        .pipe(map(teamsGroupIds => setTeamsGroupIds({teamsGroupIds})))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
  ) {
  }

}
