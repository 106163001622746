import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {FormComponent, PopinState} from "./model/model";
import {
  AssetType,
  IKHMetadataCapabilityUnitL1Value,
  IKHMetadateValue,
  PostIKHDocumentMetadata
} from "../../services/api/ikh-documents/model/model";
import {FileObj} from "@quantmetry/ui-components";
import {IKHOpportunity} from "../../services/api/ikh-opportunities/model/model";
import {validSecStatus} from "../../services/api/slide-finder/model/model";
import {Utils} from "../../utils/utils";
import {IkhDocumentsService} from "../../services/api/ikh-documents/ikh-documents.service";
import {IkhOpportunitiesService} from "../../services/api/ikh-opportunities/ikh-opportunities.service";
import * as moment from "moment/moment";
import {ActivatedRoute, Router} from "@angular/router";
import {UserProfile} from "../../services/api/user-profile/model/model";
import {Store} from "@ngrx/store";
import {selectUser} from "../../store/user/user.selectors";
import {countries} from "../../utils/countries.utils";

export interface FileUpload {
  file: File;
  fileCategory: string;
  fileCorrelationID: string;
  isPreUploaded: boolean;
}

@Component({
  selector: 'app-new-asset-page',
  templateUrl: './new-asset-page.component.html',
  styleUrls: ['./new-asset-page.component.scss']
})
export class NewAssetPageComponent implements OnInit {

  isSecOpen: boolean = false;
  isContributorsOpen: boolean = false
  isDropdown: boolean[] = [];
  opportunityDropDown = false;
  isSecStatusFilled = false;
  assetDetailOpened = false;
  listContributors: string[] = [];
  selectedOpportunityLead: string = "";
  opportunityDataPending = false;
  selectedLead: UserProfile = {
    user_oid: "",
    user_name: "",
    email: "",
    grade: "",
    localisation: "",
    capability_unit: "",
    capability_unit_l1: "",
    description: "",
    expertises: [],
    nb_docs: 0
  };
  selectedContributors: UserProfile[] = [];
  popinState: PopinState = PopinState.EditMetadata;
  selectedUploadOption: 'Link' | 'DragAndDrop' = 'Link';
  fileLink: string = "";
  isPreUploadingLink = false;
  errorPreUploadingLink: string = "";
  files: FileUpload[] = [];
  filesFirstOpen: string[] = [];
  AssetType = AssetType;
  selectedTrackAsset: AssetType | undefined;
  addImageText = `<span class='desktop-body2-regular' style='color:white !important'>Add file or drag and drop here<span/><br/>`
  addFormatText = `<span class='legend'>Accepted formats: ppt only<span/><br/>
                         <span class='legend'>Maximum file size: 100 Mo<span/>`;
  selectedOpportunity?: IKHOpportunity;
  searchedOpportunities: IKHOpportunity[] = [];
  pendingSearchOpportunity?: string = undefined;
  form: { [formComponentName: string]: FormComponent } = {};
  industries: IKHMetadateValue[];
  capabilityUnits: IKHMetadateValue[];
  capabilityUnitsL1: IKHMetadataCapabilityUnitL1Value[];
  searchValue: string = "";
  uploadedFileCount = 0;
  utils: Utils = new Utils();
  isFormValid: boolean;
  secText = "SEC 0 - Public:\n" +
    "-> Internal and external use free, also for mass communication\n" +
    "-> For project asset docs free targeted use only, no mass communication\n" +
    "SEC 1 - Company Confidential:\n" +
    "-> Internally free for reading and sharing within CG\n" +
    "-> Externally use according to set permission status only \n" +
    "SEC 2 - Restricted Doc:\n" +
    "-> Internally can only be shared on a “need to know” basis\n" +
    "-> External use of an entire SEC 2 document is strictly prohibited"
  globalText: string = "By default your assets should be shared globally. " +
    "If any special restriction occurs, you may select your country. " +
    "Only persons within your country will be able to access the asset, and it will be stored on a dedicated local server."
  secStatusItems = [
    {name: "SEC 0", isSelected: false},
    {name: "SEC 1", isSelected: false},
    {name: "SEC 2", isSelected: false},
  ];
  projectAssetCategories: string[] = ["Proposal", "Credential", "Deliverable", "Project Summary"];
  companyAssetCategories: string[] = ["Capgemini Studies", "Invent Approaches or Methods", "Training, Marketing and Offers", "Knowledge Nugget"];
  fileCategories: string[] = [];
  isReadOnly = false;
  shareGlobal = true;
  countryCode: string = "";

  protected readonly PopinState = PopinState;

  constructor(
    public ikhProposalsService: IkhDocumentsService,
    public ikhOpportunitiesService: IkhOpportunitiesService,
    public route: ActivatedRoute,
    private router: Router,
    public elementRef: ElementRef,
    public store: Store
  ) {
  }

  ngOnInit(): void {
    this.opportunityDataPending = true;
    this.initializeEmptyForm();
    this.retrieveOpportunityById();
    this.ikhOpportunitiesService.searchIkhOpportunities("").subscribe(res => {
      this.searchedOpportunities = res.ikh_opportunities;
    });
    this.ikhProposalsService.getIkhDocumentMetadata().subscribe(res => {
      this.industries = res.industries;
      this.capabilityUnits = res.capability_units;
      this.capabilityUnitsL1 = res.capability_unit_l1;
    });
    this.store.select(selectUser).subscribe(user => {
      if(user){
        this.countryCode = user.countryCode;
      }
    });
  }

  retrieveOpportunityById(): void {
    const opportunityId = this.route.snapshot.paramMap.get('opportunity_id');
    if (opportunityId !== null && opportunityId !== undefined) {
      this.ikhOpportunitiesService.searchOpportunitiesById(opportunityId).subscribe(res => {
        if (res) {
          this.selectOpportunity(res);
          this.selectedTrackAsset = AssetType.ProjectAsset;
          this.assetDetailOpened = true;
        }
        this.opportunityDataPending = false;
      })
    } else {
      this.opportunityDataPending = false;
    }
  }

  selectAssetType(type: AssetType) {
    this.selectedTrackAsset = type;
    this.assetDetailOpened = true;
    if (type === AssetType.ProjectAsset) {
      this.fileCategories = this.projectAssetCategories;
    } else {
      this.fileCategories = this.companyAssetCategories;
    }
  }

  selectFileCategory(index: number, category: string) {
    this.files[index].fileCategory = category;
    this.isDropdown[index] = false;
  }

  selectSecStatus(index: number) {
    this.secStatusItems.forEach((item, i) => {
      item.isSelected = i === index
    });
    this.form["sec_status"].content = "SEC " + index
    this.isContributorsOpen = true;
    this.isSecStatusFilled = true;

  }

  selectUploadOption(option: 'Link' | 'DragAndDrop') {
    if(this.isPreUploadingLink){
      return;
    }
    this.selectedUploadOption = option;
  }

  onFileLinkInput(newFileLink: string) {
    this.fileLink = newFileLink;
    if(newFileLink.length == 0){
      this.errorPreUploadingLink = "";
      return;
    }
    if(this.fileLink.indexOf('Shared%20Documents/') === -1){
      this.errorPreUploadingLink = "The link is not valid";
      return;
    }
    const fileLinkParts = this.fileLink.split('Shared%20Documents/');
    const filename = fileLinkParts[1] ? decodeURIComponent(fileLinkParts[1].split('?')[0].split('/').pop() || '') : '';
    if(filename.indexOf("pptx") === -1){
      this.errorPreUploadingLink = "The file is not a valid pptx file";
      return;
    }
    this.isPreUploadingLink = true;
    this.isDropdown.push(false);
    this.errorPreUploadingLink = "";
    this.ikhProposalsService.postIkhDocumentPreUploadLink({link: this.fileLink}).subscribe(
      res => {
        this.files.push({
          file: new File([''], filename),
          fileCategory: "",
          fileCorrelationID: res.correlationID,
          isPreUploaded: true
        });
        this.fileLink = "";
        this.isPreUploadingLink = false;
        setTimeout(() => {
          this.isDropdown.push(true);
        }, 500);
      },
      () => {
        this.errorPreUploadingLink = "The link is not valid";
        this.isPreUploadingLink = false;
      }
    );
  }

  onFileInput(fileObj: FileObj) {
    let file = fileObj.item;
    if (file) {
      if (
        !file.name.endsWith(".pptx") ||
        file.size > 10 ** 8 ||
        file.size == 0
      ) {
        setTimeout(() => file = undefined, 1);
      }
    }
    if (file) {
      this.files.push({file: file, fileCategory: "", fileCorrelationID: "", isPreUploaded: false});
      setTimeout(() => {
        this.isDropdown.push(true);
      }, 500);
      this.ikhProposalsService.postIkhDocumentPreUpload(file).subscribe(res => {
          this.files.forEach((fileItem) => {
            if (fileItem.file.name === file?.name) {
              fileItem.fileCorrelationID = res.correlationID;
              fileItem.isPreUploaded = true;
            }
          });
        }
      )
    }
  }

  removeFileFromList(fileId: number): void {
    if (fileId < this.files.length && fileId >= 0) {
      this.files.splice(fileId, 1);
    }
  }

  openDropDown(index: number) {
    this.isDropdown[index] = !this.isDropdown[index];
  }

  public fileByteToSizeString(nbbytes: number) {
    if (nbbytes > 1000 * 1000) {
      return Math.floor(nbbytes / (1000 * 1000)) + " MB";
    } else if (nbbytes > 1000) {
      return Math.floor(nbbytes / 1000) + " KB";
    }
    return Math.floor(nbbytes) + " B";
  }

  onDateChange(value: string): void {
    let firstSlashIndex = value.indexOf('/');
    let secondSlashIndex = value.lastIndexOf('/');
    let month =firstSlashIndex != -1 ? value.substring(firstSlashIndex +1, value.length) : '';
    if (value.length == 1 && !['1', '2', '3','0'].includes(value)) {
      value = '0' + value + '/';
    } else if (month.length ==1 && !['1','0'].includes(month)) {
      value = value + '/' ;
    } else if (value.length == 2 && firstSlashIndex === -1) {
      value = value + '/' ;
    } else if (value.length == 4 && firstSlashIndex==1 && secondSlashIndex == firstSlashIndex) {
      value = value + '/' ;
    } else if (value.length == 5 && secondSlashIndex == firstSlashIndex) {
      value = value + '/' ;
    }

    firstSlashIndex = value.indexOf('/');
    secondSlashIndex = value.lastIndexOf('/');
    if (firstSlashIndex != secondSlashIndex) {
      const day = value.substring(0, firstSlashIndex);
      const month = value.substring(firstSlashIndex + 1, secondSlashIndex);
      if (day.length == 1) {
        value = '0' + value;
        firstSlashIndex += 1;
        secondSlashIndex += 1;
      }
      if (month.length == 1) {
        value = value.substring(0, firstSlashIndex + 1) + '0' + month + value.substring(secondSlashIndex, value.length);
      }
    }
    if (value.length > 10) {
      value = value.substring(0, 10);
    }

    this.form['created_date'].content = value;
  }



  initializeEmptyForm(): void {
    this.form['title'] = {content: "", isValid: (value) => !!value, valid: true};
    this.form['opportunity_id'] = {content: "OP# ", isValid: (value) => !!value, valid: true};
    this.form['account'] = {content: "", isValid: (value) => !!value, valid: true};
    this.form['opportunity_lead'] = {
      content: "",
      isValid: (value) => value.endsWith("@capgemini.com") && this.validateEmail(value),
      valid: true
    };
    this.form['created_date'] = {content: "", isValid: (value) => this.validateDate(value), valid: true};

    this.form['industry'] = {
      content: "",
      isValid: (value) => !!value && this.industries.map(i => i.value).indexOf(value) > -1,
      valid: true
    };
    this.form['capability_unit'] = {
      content: "",
      isValid: (value) => !!value && this.capabilityUnits.map(i => i.value).indexOf(value) > -1,
      valid: true
    };
    this.form['capability_unit_l1'] = {
      content: "",
      isValid: (value) => !!value && this.capabilityUnitsL1.map(i => i.value).indexOf(value) > -1,
      valid: true
    };
    this.form['sec_status'] = {
      content: "",
      isValid: (value) => validSecStatus.indexOf(value) > -1, valid: true
    };
  }

  validateEmail(email: string): boolean {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  validateDate(date: string): boolean {
    return (moment(date, "DD/MM/YYYY", true).isValid() || moment(date, "DD-MM-YYYY", true).isValid());
  }

  getSearchedOpportinuties(): IKHOpportunity[] {
    if (!this.searchValue || !!this.pendingSearchOpportunity) {
      return [];
    }
    const searchValue = this.searchValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
    return this.searchedOpportunities.filter(
      opportunity => (opportunity.opportunity_id.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(searchValue) > -1 ||
        opportunity.opportunity_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(searchValue) > -1 ||
        opportunity.account.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(searchValue) > -1)
    ).slice(0, 10);
  }

  selectOpportunity(opportunity: IKHOpportunity) {
    this.selectedOpportunityLead = opportunity.opportunity_lead;
    this.opportunityDropDown = false;
    this.selectedOpportunity = opportunity;
    this.isSecOpen = true;
    this.searchValue = opportunity.opportunity_id;
    this.form['title'].content = opportunity.opportunity_name;
    this.form['account'].content = opportunity.account;
    this.form['opportunity_id'].content = "OP# " + opportunity.opportunity_id;
    this.form['capability_unit'].content =
      this.capabilityUnits.map(v => v.value).includes(opportunity.capability_unit) ? opportunity.capability_unit : "";
    this.form['capability_unit_l1'].content =
      this.capabilityUnitsL1.map(v => v.value).includes(opportunity.capability_unit_l1) ? opportunity.capability_unit_l1 : "";
    if (opportunity.created_date) {
      this.form['created_date'].content = this.utils.formatStringDateToDDMMYYYY(opportunity.created_date);
    }
    this.form['industry'].content =
      this.industries.map(v => v.value).includes(opportunity.industry) ? opportunity.industry : "";
  }

  getSearchedIndustries(): IKHMetadateValue[] {
    return this.industries.filter(
      i => i.value.toLowerCase().includes(this.form['industry'].content.toLowerCase())
    );
  }

  getSearchedCapabilityUnits(): IKHMetadateValue[] {
    return this.capabilityUnits.filter(
      c => c.value !== "Enterprise Transformation" && c.value.toLowerCase().includes(
        this.form['capability_unit'].content.toLowerCase()
      )
    );
  }

  getSearchedCapabilityUnitsL1(): IKHMetadataCapabilityUnitL1Value[] {
    return this.capabilityUnitsL1.filter(
      c => c.value.toLowerCase().includes(this.form['capability_unit_l1'].content.toLowerCase()) &&
        (c.capability_unit === this.form['capability_unit'].content || !this.form['capability_unit'].content)
    );
  }

  confirmUpload() {
    if (this.selectedTrackAsset === AssetType.CompanyAsset) {
      delete this.form['title'];
      delete this.form['account'];
      delete this.form['opportunity_lead'];
      delete this.form['opportunity_id'];
    }
    this.isFormValid = this.validateForm();
    this.popinState = PopinState.UploadingFile;
    this.uploadedFileCount = 0;
    if (this.isFormValid && this.files && this.selectedTrackAsset) {
      for (const file of this.files) {
        const metadata: PostIKHDocumentMetadata = {
          title: this.form['title']?.content,
          account: this.form['account']?.content,
          opportunity_lead: this.form['opportunity_lead']?.content,
          opportunity_id: !!this.form['opportunity_id'] ? this.form['opportunity_id'].content : undefined,
          capability_unit: this.form['capability_unit'].content,
          capability_unit_l1: this.form['capability_unit_l1'].content,
          industry: this.form['industry'].content,
          created_date: moment(this.form['created_date'].content, "DD-MM-YYYY").format(),
          sec_status: this.form['sec_status'].content,
          asset_categories: file.fileCategory,
        };
        this.ikhProposalsService.postIkhDocumentUpload(
          file.fileCorrelationID,
          file.file.name,
          metadata,
          this.selectedTrackAsset,
          this.listContributors,
          this.shareGlobal
        ).subscribe(() => {
          this.uploadedFileCount++;
          if (this.uploadedFileCount === this.files.length) {
            this.popinState = PopinState.Done;
          }
        });
      }
    }
  }

  validateForm(): boolean {
    let isFormValid = true;
    let fieldToSkip: string[] = [];
    if (this.selectedTrackAsset === AssetType.CompanyAsset) {
      fieldToSkip = ["opportunity_id", "title", "account", "opportunity_lead"]
    }
    for (const formComponent in this.form) {
      if (!fieldToSkip.includes(formComponent)) {
        const isFormComponentValid = this.validateFormInput(this.form[formComponent]);
        isFormValid = isFormValid && isFormComponentValid;
      }
    }
    return isFormValid;
  }

  validateFormInput(formInput: FormComponent): boolean {
    formInput.valid = formInput.isValid(formInput.content);
    return formInput.valid;
  }

  selectFieldValue(field: string, value: string) {
    this.form[field].content = value;
    this.form[field].showDropdown = false;
    this.form[field].valid = true;
    if (field === 'capability_unit_l1') {
      this.isSecOpen = true;
    }
  }

  checkCompletedAssetDetails() {
    let isAssetDetailsFilled = true;
    const additionalFields = ["opportunity_id", "title", "account"]
    let requiredFields = ["capability_unit", "capability_unit_l1", "industry", "created_date"]
    if (this.selectedTrackAsset === AssetType.ProjectAsset) {
      requiredFields = [...additionalFields]
    }
    for (const field of requiredFields) {
      if (this.form[field]) {
        if (this.form[field].content.length === 0) {
          isAssetDetailsFilled = false;
        }
      } else {
        isAssetDetailsFilled = false;
      }
    }

    return isAssetDetailsFilled
  }

  checkCompletedSecStatus() {
    return this.isSecStatusFilled;
  }

  enterReadOnlyMode() {
    if (this.validateForm()) {
      this.isReadOnly = true;
    }
  }

  cancelReadOnlyMode() {
    this.isReadOnly = false;
  }

  getSecStatusDescriptionAndColor(): { color: string } | null {
    if (this.form["sec_status"].content === "SEC 0") {
      return {
        color: "green"
      }
    }
    if (this.form["sec_status"].content === "SEC 1") {
      return {
        color: "orange"
      }
    }
    if (this.form["sec_status"].content === "SEC 2") {
      return {
        color: "red"
      }
    }
    return null;
  }

  handleLead(user: UserProfile) {
    this.form['opportunity_lead'].content = user.email;
    this.selectedOpportunityLead = user.email;
    this.selectedLead = user;
  }

  handleContributors(users: UserProfile[]) {
    this.listContributors = users.map(user => user.email);
    this.selectedContributors = users;
  }

  goToHomePage() {
    this.router.navigate(['/']);
  }

  reloadPage() {
    window.location.reload();
  }

  isAssetReady() {
    return this.checkCompletedAssetDetails() &&
      this.checkCompletedSecStatus() &&
      this.hasFilesAndIsAllFilesCategoryFilled()
  }

  hasFilesAndIsAllFilesCategoryFilled() {
    return this.files.length > 0 &&
      this.files.filter(f => !f.fileCategory).length === 0;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const dropdownMenus = this.elementRef.nativeElement.querySelectorAll('.dropdown');

    let clickedInsideDropdown = false;

    dropdownMenus.forEach((dropdownMenu: HTMLElement) => {
      if (dropdownMenu.contains(event.target as Node)) {
        clickedInsideDropdown = true;
      }
    });

    if (!clickedInsideDropdown) {
      this.opportunityDropDown = false;
      this.form['industry'].showDropdown = false;
      this.form['capability_unit'].showDropdown = false;
      this.form['capability_unit_l1'].showDropdown = false;
    }
  }

  onChangeOpportunitySearch(event: string) {
    this.form['opportunity_id'].content = event;
    this.searchValue = event;
    this.form['opportunity_id'].valid = !!this.form['opportunity_id'].content;
    this.pendingSearchOpportunity = event;
    this.ikhOpportunitiesService.searchIkhOpportunities(this.searchValue).subscribe(res => {
      this.searchedOpportunities.push(...res.ikh_opportunities.filter(
        opportunity => !this.searchedOpportunities.find(o => o.opportunity_id === opportunity.opportunity_id))
      );
      if (this.pendingSearchOpportunity === event) {
        this.pendingSearchOpportunity = undefined;
      }
    });
  }

  getCountryName() {
    return countries[this.countryCode].name;
  }
}
