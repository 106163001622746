import {selectAccessibleFeatures, selectUser} from "../store/user/user.selectors";
import {UserType} from "../store/user/user.reducer";
import {Store} from "@ngrx/store";
import {AuthorizationService} from "../services/utils/authorization/authorization.service";


export abstract class IAccessibleFeatures {

  public user?: UserType;
  public accessibleFeatures: string[] = [];
  public pendingCanAccessTool: boolean = true;

  protected constructor(
    public store: Store,
    public authorizationService: AuthorizationService
  ) {
  }

  public initAccessibleFeatures() {
    this.store.select(selectUser).subscribe(res => {
      this.user = res;
    });
    this.store.select((state) => selectAccessibleFeatures(state, this.authorizationService)).subscribe(res => {
      this.accessibleFeatures = res;
      if (this.user) {
        this.pendingCanAccessTool = false;
      }
    });
  }
}
