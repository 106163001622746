import {UserState, UserType} from "./user.reducer";
import {StoreState} from "../reducers";
import {createSelector} from "@ngrx/store";
import {features} from "../../utils/countries.utils";
import {AuthorizationService} from "../../services/utils/authorization/authorization.service";


const getUserStore = (state: StoreState) => state.user;

export const selectUser: (state: any) => UserType | undefined = createSelector(
  getUserStore,
  (state: UserState) => state.user
);

export const selectAccessibleFeatures: (state: any, authorizationService: AuthorizationService) => string[] = createSelector(
  getUserStore,
  (state: UserState, authorizationService: AuthorizationService) => {
    const accessibleFeatures = [];
    for(let feature of features){
      if(authorizationService.hasAccess(feature, state.teamsGroupIds)){
        accessibleFeatures.push(feature);
      }
    }
    return accessibleFeatures;
  }
)
