import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit {

  @Input() leftValue: string = "Left";
  @Input() rightValue: string = "Right";
  @Input() value: boolean = false;

  @Output() valueChange = new EventEmitter<boolean>();

  displayValue: boolean = this.value;

  constructor() { }

  ngOnInit(): void {
    this.displayValue = this.value;
  }

  switchValue(newValue: boolean) {
    if(newValue != this.value){
      this.displayValue = newValue;
      setTimeout(() => {
        this.value = newValue;
        this.valueChange.emit(newValue);
      }, 200)
    }
  }
}
