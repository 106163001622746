import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserProfile} from "../../services/api/user-profile/model/model";
import {IkhDocumentsService} from "../../services/api/ikh-documents/ikh-documents.service";
import {AssetElement, AssetElementWithIndex} from "../../services/api/slide-finder/model/model";
import {catchError, switchMap} from "rxjs";
import {emptyUserImageBase64} from "../../utils/image.utils";
import {HttpClient} from "@angular/common/http";
import {Utils} from "../../utils/utils";
import {Router} from "@angular/router";
import {selectUser} from "../../store/user/user.selectors";
import {Store} from "@ngrx/store";
import {UserType} from "../../store/user/user.reducer";

@Component({
  selector: 'app-user-view-details',
  templateUrl: './user-view-details.component.html',
  styleUrls: ['./user-view-details.component.scss']
})
export class UserViewDetailsComponent implements OnInit {
  public userProfilestore?: UserType;
  editingDescription: boolean = false;

  @Input() user: UserProfile;
  assets: AssetElement[];
  picture: string;
  utils = new Utils();
  loaded: boolean;
  viewDetailsPosition = 0;
  expandedAssetElement?: AssetElementWithIndex;
  @Input() backText: string = "";
  @Output() closeDetailsPage: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public http: HttpClient,
    private ikhDocumentsService: IkhDocumentsService,
    public router: Router,
    public store: Store,
  ) {
  }

  ngOnInit(): void {
    this.getAssetElementUser();
    this.retrieveUserPicture();
    this.isItMyAccount()
  }

  getAssetElementUser(): void {
    this.ikhDocumentsService.getIkhDocumentByContributor(this.user.email).subscribe((res) => {
      this.assets = res;
    })
  }

  retrieveUserPicture() {
    this.http.get("https://graph.microsoft.com/v1.0/users/" + this.user.user_oid + "/photo/$value", {responseType: 'blob'}).pipe(
      switchMap(data => this.utils.blobToBase64(data, () => this.setLoaded())),
      catchError(() => [emptyUserImageBase64])
    ).subscribe(data => {
      this.picture = data;
    });
  }

  setLoaded(): void {
    this.loaded = true;
  }

  contactExpert(email: string): void {
    window.open('msteams://teams.microsoft.com/l/chat/0/0?users=' + email, '_blank');
  }

  viewElementDetails(event: AssetElementWithIndex) {
    this.viewDetailsPosition = window.scrollY;
    this.expandedAssetElement = event;
    window.scrollTo(0, 0);
  }

  backToPrincipalPage(){
    this.expandedAssetElement = undefined;
    setTimeout(() => {
      window.scrollTo(0, this.viewDetailsPosition);
    }, 0);
  }

  isItMyAccount(){
    this.store.select(selectUser).subscribe(res => {
      this.userProfilestore = res;
      if (this.user?.email === this.userProfilestore?.email) {
        this.editingDescription = true;
      }
    });
  }

  navigateToMyprofile() {
    this.router.navigate(["/profile"])
  }

}
