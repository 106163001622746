import {createReducer, on} from "@ngrx/store";
import {fetchUser, setTeamsGroupIds, setUser} from "./user.actions";

export interface UserType {
  id: string;
  displayName: string;
  givenName: string;
  email: string;
  jobTitle: string;
  officeLocation: string;
  countryCode: string;
}

export interface UserState {
  user?: UserType;
  teamsGroupIds?: string[];
}

const initialState: UserState = {};

export const userReducer = createReducer<UserState>(
  initialState,
  on(fetchUser, (state) => ({...state})),
  on(setUser, (state, {user}) => ({...state, user})),
  on(setTeamsGroupIds, (state, {teamsGroupIds}) => ({...state, teamsGroupIds})),
);
