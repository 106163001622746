import { HttpClient } from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { environment } from '../../../environments/environment';
import {Store} from "@ngrx/store";
import {selectAccessibleFeatures, selectUser} from "../../store/user/user.selectors";
import {ActivatedRoute, Router} from "@angular/router";
import {UserType} from "../../store/user/user.reducer";
import {
  FEAT_DOC_FINDER,
  FEAT_EXPERT_FINDER,
  FEAT_RFP_ASSISTANT,
  FEAT_SECURE_GPT,
  FEAT_SLIDE_FINDER, FEAT_UPLOAD
} from "../../utils/countries.utils";
import {IAccessibleFeatures} from "../../utils/IAccessibleFeatures";
import {AuthorizationService} from "../../services/utils/authorization/authorization.service";

interface GetVersionResponse {
  version: string;
}

interface PageAccess {
  title: string,
  feature?: string;
  subtitle: string,
  callToAction: string,
  routerLink: string,
  isBeta: boolean,
  enabled: boolean
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent extends IAccessibleFeatures implements OnInit {

  public version: GetVersionResponse = { version: 'not responding' };

  public pageAccessList: PageAccess[]  = [
    {title: "Slide Finder", feature: FEAT_SLIDE_FINDER, subtitle: "Converse with the knowledge hub to find the best slide for your presentation, whether by content or template", callToAction: "Start searching", routerLink: "slide-finder", isBeta: false, enabled: true},
    {title: "Doc Finder", feature: FEAT_DOC_FINDER, subtitle: "Converse with the knowledge hub to find entire decks from project proposals to company points of view, by topic or industry", callToAction: "Start searching", routerLink: "doc-finder", isBeta: false, enabled: true},
    {title: "Secure ChatGPT", feature: FEAT_SECURE_GPT, subtitle: "Interact with ChatGPT in a secure, fully Capgemini Invent dedicated environment", callToAction: "Start a conversation", routerLink: "secure-chatgpt", isBeta: false, enabled: true},
    {title: "RFP Assistant", feature: FEAT_RFP_ASSISTANT, subtitle: "Speed up your response process by chatting with your RFP and finding the most relevant assets", callToAction: "Provide information", routerLink: "rfp-assistant", isBeta: false, enabled: true},
    {title: "Expert Finder", feature: FEAT_EXPERT_FINDER, subtitle: "Find the right expert in Invent France to help boost your productivity", callToAction: "Find experts", routerLink: "expert-finder", isBeta: true, enabled: true},
  ];

  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    authorizationService: AuthorizationService,
    store: Store) {
    super(store, authorizationService);
  }

  ngOnInit(): void {
    this.http.get<GetVersionResponse>(environment.apiUrl + '/api/version').subscribe((res) => (this.version = res));
    this.pendingCanAccessTool = true;
    this.initAccessibleFeatures();
  }
  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  protected readonly FEAT_UPLOAD = FEAT_UPLOAD;

  getPageAccessList(): PageAccess[] {
    const pageAccessList: PageAccess[] = [];
    for(let pageAccess of this.pageAccessList){
      if(!pageAccess.feature || this.accessibleFeatures.includes(pageAccess.feature)){
        pageAccessList.push(pageAccess);
      }
    }
    return pageAccessList;
  }
}
