import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AssetElement} from "../../services/api/slide-finder/model/model";
import {Thumbnails} from "../../utils/thumbnails";
import {Utils} from "../../utils/utils";

import {AssetElementLog} from "../../services/api/admin/model/model";
import {UserProfile} from "../../services/api/user-profile/model/model";
import {Store} from "@ngrx/store";
import {selectSpecificUser} from "../../store/users/users.selectors";
import {IkhDocumentsService} from "../../services/api/ikh-documents/ikh-documents.service";
import {PatchIkhDocumentContactPerson,PatchIkhDocumentRequest} from "../../services/api/ikh-documents/model/model";
import {UserProfileService} from "../../services/api/user-profile/user-profile.service";
import {SavedService} from "../../services/api/saved/saved.service";
import {isDocSaved, isSlideSaved} from "../../store/saved/saved.selectors";
import {Observable, Subscription} from "rxjs";
import {
  createSaveDocAction,
  createSaveSlideAction,
  createUnsaveDocAction,
  createUnsaveSlideAction
} from "../../store/saved/saved.actions";


export interface ContactPerson {
  displayName: string,
  jobTitle: string,
  email: string
}

@Component({
  selector: 'app-asset-element-details',
  templateUrl: './asset-element-details.component.html',
  styleUrls: ['./asset-element-details.component.scss']
})
export class AssetElementDetailsComponent implements OnInit {
  currentSlideIndex: number

  @Input() assetElement: AssetElement;
  @Input() backButtonText: string;
  @Input() isDoc: boolean = false;
  @Input() displayAfterAndBackButtons: boolean;
  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  @Output() logOpenDocument: EventEmitter<AssetElementLog> = new EventEmitter<AssetElementLog>();

  saved: boolean = false;
  onSaveContainer: boolean = false;
  utils: Utils = new Utils();
  addingContributor: boolean = false;
  updatePending = false;
  errorUpdating = false;
  myUserProfile: UserProfile;

  constructor(
    public thumbnails: Thumbnails,
    public store: Store,
    public ikhDocumentsService: IkhDocumentsService,
    public savedService: SavedService,
    public userProfileService: UserProfileService) {
  }

  emptyString: string = "";
  filterNA: string = 'N/A';

  opportunityLead: ContactPerson = {
    displayName: "",
    jobTitle: "",
    email: "",
  }

  contactPersons: ContactPerson[] = []

  savedStoreSubscription: Subscription;

  ngOnInit(): void {
    this.getMyUserInfo();
    if (this.assetElement.opportunity_lead) {
      this.store.select((state) => selectSpecificUser(state, this.assetElement.opportunity_lead)).subscribe(res => {
        if (res) {
          this.opportunityLead.displayName = res.user_name;
          this.opportunityLead.jobTitle = res.grade + " " + res.capability_unit_l1;
        }
      });
    }
    if (this.assetElement.contact_persons) {
      for (let contactPerson of this.assetElement.contact_persons) {
        if (contactPerson == this.assetElement.opportunity_lead) {
          continue;
        }
        this.store.select((state) => selectSpecificUser(state, contactPerson)).subscribe(res => {
          if (res) {
            const newContact = {
              displayName: res.user_name,
              jobTitle: res.grade + " " + res.capability_unit_l1,
              email: contactPerson
            }
            this.contactPersons.push(newContact);

          }
        });
      }
    }
    this.thumbnails.fetchIkhDocumentSlideThumbnail(this.assetElement.unique_id, this.assetElement.slide_index, true);
    this.currentSlideIndex = this.assetElement.slide_index;
    this.loadNextAndPreviousAsset();
    this.resetSavedStoreSubscription();
  }

  resetSavedStoreSubscription(){
    if(this.savedStoreSubscription){
      this.savedStoreSubscription.unsubscribe()
    }
    this.savedStoreSubscription = this.store.select((state) => this.isDoc ?
      isDocSaved(state, this.assetElement.id) :
      isSlideSaved(state, {document_id: this.assetElement.id, slide_number: this.currentSlideIndex})
    ).subscribe(res => {
      this.saved = res;
    });
  }

  getPreviousThumbnail(): void {
    if (this.currentSlideIndex !== 0) {
      this.currentSlideIndex -= 1;
      this.loadNextAndPreviousAsset();
      if(!this.isDoc){
        this.resetSavedStoreSubscription();
      }
    }
  }

  getNextThumbnail(): void {
    if (this.currentSlideIndex !== this.assetElement.slides_urls.length - 1) {
      this.currentSlideIndex += 1
      this.loadNextAndPreviousAsset();
      if(!this.isDoc){
        this.resetSavedStoreSubscription();
      }
    }
  }

  contactTeams(email: string) {
    window.open('msteams://teams.microsoft.com/l/chat/0/0?users=' + email, '_blank');
  }

  openAsset() {
    const url = this.assetElement.slides_urls[this.currentSlideIndex];
    window.open(url, '_blank');
    this.logOpenDocument.emit({
      sharepoint_uri: this.assetElement.sharepoint_uri,
      slide_index: this.currentSlideIndex,
    })
  }

  copyAsset() {
    navigator.clipboard.writeText(this.assetElement.slides_urls[this.currentSlideIndex]);
  }

  loadNextAndPreviousAsset() {
    if (this.currentSlideIndex !== 0) {
      this.thumbnails.fetchIkhDocumentSlideThumbnail(this.assetElement.unique_id, this.currentSlideIndex - 1, true)
    }
    if (this.currentSlideIndex !== this.assetElement.slides_urls.length - 1) {
      this.thumbnails.fetchIkhDocumentSlideThumbnail(this.assetElement.unique_id, this.currentSlideIndex + 1, true)
    }
  }

  getSecStatusDescriptionAndColor(): { description: string, color: string } | null {
    if (this.assetElement.sec_status === "SEC 0") {
      return {
        description: "Public:\n" +
          "-> Internal and External use free, also for mass communication\n" +
          "-> For Project Asset Docs free targeted use only, no mass communication",
        color: "green"
      }
    }
    if (this.assetElement.sec_status === "SEC 1") {
      return {
        description: "Company Confidential:\n" +
          "-> Internally free for reading and sharing within CG\n" +
          "-> Externally use according to set permission status only",
        color: "orange"
      }
    }
    if (this.assetElement.sec_status === "SEC 2") {
      return {
        description: "Restricted Doc:\n" +
          "-> Internally can only be shared on a “need to know” basis\n" +
          "-> External use of an entire SEC 2 document is strictly prohibited",
        color: "red"
      }
    }
    return null;
  }

  getContacts(): ContactPerson[] {
    return this.contactPersons.filter(element => element.displayName != "");
  }

  addContributors(users: UserProfile[]) {
    this.updatePending = true;
    const newContributors: ContactPerson[] = [];
    for (const user of users) {
      const newContact: ContactPerson = {
        email: user.email,
        displayName: user.user_name,
        jobTitle: user.grade
      }

      newContributors.push(newContact);
    }
    this.updateContributors(newContributors);
  }

  updateContributors(newContributors: ContactPerson[]) {
    const patchIkhContributors: PatchIkhDocumentContactPerson = {
      contact_persons: newContributors.map(contact => contact.email),
    }
    this.ikhDocumentsService.patchIkhDocumentContributors(this.assetElement.id, patchIkhContributors).subscribe({
      next: () => {
        this.assetElement.contact_persons = patchIkhContributors.contact_persons;
        this.updatePending = false;
        this.contactPersons = newContributors;
        this.errorUpdating = false;
      },
      error: () => {
        this.updatePending = false;
        this.errorUpdating = true;

      }
    });
  }

  updateSummary(newSummary: string) {
    this.updatePending = true;
    const body: PatchIkhDocumentRequest = {
      summary: newSummary,
    }
    this.ikhDocumentsService.patchIkhDocument(this.assetElement.id, body).subscribe({
      next: () => {
        this.assetElement.summary = newSummary;
        this.updatePending = false;
        this.errorUpdating = false;
      },
      error: () => {
        this.updatePending = false;
        this.errorUpdating = true;
      }
    });
  }

  getContributorsMail(): string[] {
    return this.contactPersons.map(contact => contact.email)
  }


  getMyUserInfo() {
    this.userProfileService.getUserInfo().subscribe((res) => {
      this.myUserProfile = res;
    })
  }

  isMyProfileContributor(): boolean {
    const contributor_mail = this.getContributorsMail();
    return !this.myUserProfile || contributor_mail.includes(this.myUserProfile.email) || this.assetElement.opportunity_lead === this.myUserProfile.email;
  }

  addMeAsAContributor() {
    if (this.myUserProfile) {
      const myContact: ContactPerson = {
        email: this.myUserProfile.email,
        displayName: this.myUserProfile.user_name,
        jobTitle: this.myUserProfile.grade
      }
      if (!this.getContributorsMail().includes(myContact.email)) {
        this.updatePending = true;
        const currentContributors = [...this.contactPersons, myContact];
        this.updateContributors(currentContributors);
      }
    }
  }

  canEditContributors() {
    return this.assetElement.sharepoint_uri.startsWith("/sites/Knowyourassets/")
  }

  saveAsset() {
    if(this.isDoc){
      this.store.dispatch(createSaveDocAction(this.assetElement.id));
      this.savedService.postSaveIkhDoc(this.assetElement.id).subscribe({
        next: () => {
        }
      });
    } else {
      this.store.dispatch(createSaveSlideAction(this.assetElement.id, this.currentSlideIndex));
      this.savedService.postSaveIkhSlide(this.assetElement.id, this.currentSlideIndex).subscribe({
        next: () => {
        }
      });
    }
  }

  unsaveAsset() {
    if(this.isDoc){
      this.store.dispatch(createUnsaveDocAction(this.assetElement.id));
      this.savedService.deleteSavedIkhDoc(this.assetElement.id).subscribe({
        next: () => {
        }
      });
    } else {
      this.store.dispatch(createUnsaveSlideAction(this.assetElement.id, this.currentSlideIndex));
      this.savedService.deleteSavedIkhSlide(this.assetElement.id, this.currentSlideIndex).subscribe({
        next: () => {
        }
      });
    }
  }
}
