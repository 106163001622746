import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {
  AssetType,
  GetIkhDocumentMetadataResponse, PatchIkhDocumentContactPerson,
  PostIKHDocumentMetadata,
  PostIKHDocumentPreUploadLinkRequest,
  PostIKHDocumentPreUploadResponse,
  PatchIkhDocumentRequest,
} from "./model/model";
import {AssetElement} from "../slide-finder/model/model";


@Injectable({
  providedIn: 'root'
})
export class IkhDocumentsService {

  constructor(
    public http: HttpClient
  ) {
  }

  postIkhDocumentPreUpload(file: File): Observable<PostIKHDocumentPreUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<PostIKHDocumentPreUploadResponse>(environment.apiUrl + '/ikh-documents/pre-upload', formData);
  }

  postIkhDocumentPreUploadLink(body: PostIKHDocumentPreUploadLinkRequest): Observable<PostIKHDocumentPreUploadResponse> {
    return this.http.post<PostIKHDocumentPreUploadResponse>(environment.apiUrl + '/ikh-documents/pre-upload-link', body);
  }

  postIkhDocumentUpload(correlation_id: string, filename: string, metadata: PostIKHDocumentMetadata, asset_type: AssetType, contributors: string[], shareGlobal: boolean): Observable<any> {
    return this.http.post(environment.apiUrl + "/ikh-documents?share_global=" + shareGlobal, {
      correlation_id,
      filename,
      metadata,
      asset_type,
      contributors,
    });
  }

  getIkhDocumentMetadata(): Observable<GetIkhDocumentMetadataResponse> {
    return this.http.get<GetIkhDocumentMetadataResponse>(environment.apiUrl + "/ikh-documents/metadata");
  }

  getIkhDocumentSlideThumbnail(unique_id: string, slide_index: number, large: boolean): Observable<Blob> {
    return this.http.get(
      environment.apiUrl + "/ikh-documents/" + unique_id + "/thumbnails/" + slide_index + "?large=" + large,
      {responseType: 'blob'}
    );
  }

  patchIkhDocumentContributors(doc_id: number, body: PatchIkhDocumentContactPerson): Observable<void> {
    return this.http.patch<void>(environment.apiUrl + "/ikh-documents/" + doc_id + "/contributors", body);
  }

  patchIkhDocument(doc_id: number, body: PatchIkhDocumentRequest): Observable<void> {
    return this.http.patch<void>(environment.apiUrl + "/ikh-documents/" + doc_id, body);
  }

  getIkhDocumentByContributor(email: string): Observable<AssetElement[]> {
    return this.http.get<AssetElement[]>(environment.apiUrl + "/ikh-documents/" + email);
  }

}
