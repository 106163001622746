import { Injectable } from '@angular/core';
import {roles} from "../../../roles";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor() { }

  hasAccess(feature?: string, teamsGroupIds?: string[]) {
    if (!teamsGroupIds || teamsGroupIds.length === 0) {
      return false;
    }
    if (!feature) {
      return true;
    }
    let access: boolean = false;
    for (let role of Object.keys(roles)) {
      if (roles[role].features.includes(feature)) {
        if (roles[role].mailing_lists.some(groupId => !!teamsGroupIds && teamsGroupIds.includes(groupId))) {
          access = true;
          break;
        }
      }
    }
    return access;
  }
}
