import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from "rxjs/operators";
import {AuthService} from "@quantmetry/api-services";
import * as countries from "./countries.utils";
import {AuthorizationService} from "../services/utils/authorization/authorization.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public teamsGroupIds?: string[];

  public constructor(
    public authService: AuthService,
    public router: Router,
    public authorizationService: AuthorizationService
  ) {
  }

  checkAccessAndRedirectIfUnauthorized(feature: string): boolean {
    const access = this.authorizationService.hasAccess(feature, this.teamsGroupIds);
    if (!access) {
      this.router.navigate(["/"]);
    }
    return access;
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const feature: string | undefined = route.data['feature'];
    if (!feature) {
      return of(true);
    }
    if (this.teamsGroupIds != null) {
      return of(this.checkAccessAndRedirectIfUnauthorized(feature));
    }
    return this.authService.getUserGroupIds().pipe(map(teamsGroupIds => {
      this.teamsGroupIds = teamsGroupIds;
      return this.checkAccessAndRedirectIfUnauthorized(feature);
    }));
  }

}
