import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AdminService} from "../../services/api/admin/admin.service";
import {IkhDocument, IkhProposalsFailureUpload} from "../../services/api/admin/model/model";
import {Utils} from "../../utils/utils";
import {IngestionStatus} from "../../services/api/slide-finder/model/model";


@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {

  proposals: IkhDocument[];
  downloadingSearchLogs: boolean = false;
  downloadingSlideFinder: boolean = false;
  downloadingUsage: boolean = false;
  DBVersion: string;
  ikhProposalsFailureUploads: IkhProposalsFailureUpload[];
  showingFailureUploads: boolean = false;
  utils = new Utils();
  pendingRestartIngestion: {[key: string]: boolean} = {
    "FAILURE": false,
    "FILE_UNAVAILABLE": false
  };


  constructor(public http: HttpClient, public adminService: AdminService) {
  }

  ngOnInit(): void {
    this.getIkhProposalsFailureUploads();
    this.adminService.getDBVersion().subscribe((res) => {
      this.DBVersion = res;
    });
    this.getIkhProposals();
  }

  getIkhProposals(): void {
    this.adminService.getIkhDocuments().subscribe((res) => (this.proposals = res.documents));
  }

  deleteIkhProposal(id: string): void {
    this.adminService.deleteIkhDocument(id).subscribe((res) => {
      this.getIkhProposals()
    });
  }

  deleteIkhProposals(): void {
    this.adminService.deleteIkhDocuments().subscribe(() => {
      this.getIkhProposals();
    })
  }

  downloadAssetRetrieverSlideFinderLogs(): void {
    this.downloadingSearchLogs = true;
    this.adminService.downloadAssetRetrieverSlideFinderLogs().subscribe((res) => {
      const blob: Blob = new Blob([res], {type: 'text; charset=utf-8'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = "asset-retriever-slide-finder-logs.csv";
      link.click();
      this.downloadingSearchLogs = false;
    });
  }


  downloadUsageLogs(): void {
    this.downloadingUsage = true;
    this.adminService.downloadUsageLogs().subscribe((res) => {
      const blob: Blob = new Blob([res], {type: 'text; charset=utf-8'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = "usage-logs.csv";
      link.click();
      this.downloadingUsage = false;
    });
  }

  downloadErrorLogs(): void {
    this.downloadingUsage = true;
    this.adminService.downloadErrorLogs().subscribe((res) => {
      const blob: Blob = new Blob([res], {type: 'text; charset=utf-8'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = "error-logs.csv";
      link.click();
      this.downloadingUsage = false;
    });
  }

  resetIngestionFailures(ingestionStatus: IngestionStatus): void {
    this.pendingRestartIngestion[ingestionStatus] = true;
    this.adminService.resetIngestionFailures(ingestionStatus).subscribe(
      {
        next: () => {
          this.getIkhProposals();
          this.pendingRestartIngestion[ingestionStatus] = false;
        },
        error: (error) => alert('Reset failed: ' + error.message)
      });
  }

  getIkhProposalsFailureUploads(): void {
    this.adminService.getIkhProposalsFailureUploads().subscribe((res) => {
      this.ikhProposalsFailureUploads = res;
    });
  }

  deleteIkhProposalsFailureUpload(id: number): void {
    this.adminService.deleteIkhProposalsFailureUpload(id).subscribe(() => {
      this.getIkhProposalsFailureUploads();
    });
  }

  setIkhProposalsFailureUploadToProcessed(id: number): void {
    this.adminService.setIkhProposalsFailureUploadToProcessed(id).subscribe(() => {
      this.getIkhProposalsFailureUploads();
    });
  }

  getNbDocumentByStatus(status: string) {
    return this.proposals.filter((proposal) => proposal.ingestion_status === status).length;
  }
}
