<div (window:resize)="onResize()">
  <qmtopbar
    applicationName=""
    [menuItems]="menuItems"
    [authParam]="authParam"
    [navigationItems]="getNavigationItems()"
    [imageSource]="lightTopBar? '/assets/logo_nom_noir.svg' : '/assets/logo_nom_blanc.svg'"
    (onLogoClick)="navigateToHomePage()"
    [logoutText]="'Logout'"
    [loginText]="'Login'"
    [isLightTheme]="lightTopBar"
  >
  </qmtopbar>
</div>
<div *ngFor="let rfpId of doneRfpIds" [class]="'toaster-container ' +(displayRfpIds.indexOf(rfpId) > -1 ? 'show': '')">
  <qmtoaster
    title="RFP Assistant"
    color="var(--primary-color)"
    description="The analysis of your RFP is complete."
    logo="/assets/logo.svg"
    (close)="closeRfpToaster(rfpId)"
    (discoverIt)="discoverRfp(rfpId)"
  >
  </qmtoaster>
</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
