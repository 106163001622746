import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {IkhDocumentsService} from "../services/api/ikh-documents/ikh-documents.service";

@Injectable({
  providedIn: 'root'
})
export abstract class Thumbnails {

  private slideThumbnails: { [document_unique_id: string]: { [slide_index: number]: { large?: string, small?: string } } } = {};

  protected constructor(public http: HttpClient, public ikhProposalsService: IkhDocumentsService) {
  }

  fetchIkhDocumentSlideThumbnail(unique_id: string, slide_index: number, large: boolean): void {
    if (this.getThumbnail(unique_id, slide_index, large)) {
      return;
    }
    this.ikhProposalsService.getIkhDocumentSlideThumbnail(unique_id, slide_index, large).subscribe((response: Blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          if (!this.slideThumbnails[unique_id]) {
            this.slideThumbnails[unique_id] = {};
          }
          if (!this.slideThumbnails[unique_id][slide_index]) {
            this.slideThumbnails[unique_id][slide_index] = {};
          }
          const res = "data:image/png;base64," + reader.result.slice(21);
          if (large) {
            this.slideThumbnails[unique_id][slide_index].large = res;
          } else {
            this.slideThumbnails[unique_id][slide_index].small = res;
          }
        }
      }
      reader.readAsDataURL(response);
    })
  }

  getThumbnail(document_unique_id: string, slide_index: number, large: boolean): string | undefined {
    if (!this.slideThumbnails[document_unique_id] || !this.slideThumbnails[document_unique_id][slide_index]) {
      return undefined;
    }
    if(large){
      return this.slideThumbnails[document_unique_id][slide_index].large;
    } else {
      return this.slideThumbnails[document_unique_id][slide_index].small;
    }
  }
}
