import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {NewAssetPageComponent} from "./pages/new-asset-page/new-asset-page.component";
import {AuthGuard} from "./utils/auth.guard";
import {AdminPageComponent} from "./pages/admin-page/admin-page.component";
import {SlideFinderPageComponent} from "./pages/slide-finder-page/slide-finder-page.component";
import {DocFinderPageComponent} from "./pages/doc-finder-page/doc-finder-page.component";
import {RfpAssistantComponent} from "./pages/rfp-assistant/rfp-assistant.component";
import {RfpDetailsComponent} from "./pages/rfp-assistant/rfp-details/rfp-details.component";
import {ProfilePageComponent} from "./pages/profile-page/profile-page.component";
import {SecureChatGptComponent} from "./pages/secure-chatgpt/secure-chatgpt.component";
import {ExpertFinderComponent} from "./pages/expert-finder/expert-finder.component";
import {SavedAssetsComponent} from "./pages/saved-assets/saved-assets.component";
import {
  FEAT_ADMIN,
  FEAT_DOC_FINDER, FEAT_EXPERT_FINDER,
  FEAT_RFP_ASSISTANT,
  FEAT_SECURE_GPT,
  FEAT_SLIDE_FINDER,
  FEAT_UPLOAD
} from "./utils/countries.utils";


const routes: Routes = [
  {
    path: 'secure-chatgpt',
    canActivate: [MsalGuard, AuthGuard],
    component: SecureChatGptComponent,
    data: { feature: FEAT_SECURE_GPT }
  },
  {
    path: 'upload-docs',
    canActivate: [MsalGuard, AuthGuard],
    component: NewAssetPageComponent,
    data: { feature: FEAT_UPLOAD }
  },
    {
    path: 'upload-docs/:opportunity_id',
    canActivate: [MsalGuard, AuthGuard],
    component: NewAssetPageComponent,
    data: { feature: FEAT_UPLOAD }
  },
  {
    path: 'admin',
    canActivate: [MsalGuard, AuthGuard],
    component: AdminPageComponent,
    data: { feature: FEAT_ADMIN }
  },
  {
    path: 'slide-finder',
    canActivate: [MsalGuard, AuthGuard],
    component: SlideFinderPageComponent,
    data: { feature: FEAT_SLIDE_FINDER }
  },
  {
    path: 'doc-finder',
    canActivate: [MsalGuard, AuthGuard],
    component: DocFinderPageComponent,
    data: { feature: FEAT_DOC_FINDER }
  },
  {
    path: 'doc-finder/:id',
    canActivate: [MsalGuard, AuthGuard],
    component: DocFinderPageComponent,
    data: { feature: FEAT_RFP_ASSISTANT }
  },
  {
    path: 'rfp-assistant',
    canActivate: [MsalGuard, AuthGuard],
    component: RfpAssistantComponent,
    data: { feature: FEAT_RFP_ASSISTANT }
  },
  {
    path: 'rfp-assistant/:id',
    canActivate: [MsalGuard, AuthGuard],
    component: RfpDetailsComponent,
    data: { feature: FEAT_RFP_ASSISTANT }
  },
  {
    path: 'profile',
    canActivate: [MsalGuard, AuthGuard],
    component: ProfilePageComponent
  },
  {
    path: 'saved-assets',
    canActivate: [MsalGuard, AuthGuard],
    component: SavedAssetsComponent,
  },
  {
    path: 'expert-finder',
    canActivate: [MsalGuard, AuthGuard],
    component: ExpertFinderComponent,
    data: { feature: FEAT_EXPERT_FINDER }
  },
  {
    path: 'expert-finder/:id',
    canActivate: [MsalGuard, AuthGuard],
    component: ExpertFinderComponent,
    data: { feature: FEAT_RFP_ASSISTANT }
  },
  {
    path: '',
    component: HomePageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
