import {Mode} from "../../../../components/asset-element-snippet/asset-element-snippet.component";
import {AssetType} from "../../ikh-documents/model/model";

export interface GetIkhDocumentResponse {
  documents: IkhDocument[];
}

export interface IkhDocument {
  id: string,
  unique_id: string,
  name: string,
  content_length: number,
  sharepoint_uri: string,
  last_update_date: string,
  created_date: string,

  last_watcher_sync_date: string;
  ingestion_status: 'TODO' | 'SUCCESS' | 'FAILURE';
  ingestion_date: string;
}


export type SearchAction = 'view_details' | 'open_document'

export interface AssetElementLog {
  sharepoint_uri: string;
  slide_index: number;
}

export interface PostSearchActionLogRequest extends AssetElementLog {
  search_id: number
  search_action: SearchAction
  search_mode: Mode
}

export type IngestionJobType = "Deck Embeddings" | "Slide Embeddings" | "Thumbnails"

export interface IkhProposalsFailureUpload {
  id: number
  correlation_id: string
  filename: string
  user_email: string
  post_ikh_proposal_metadata_json: string
  asset_type: AssetType
  failure_datetime: string
  error_trace: string
  processed: boolean
}
