<div *ngIf="showOpportunityLead">
  <div class="body2">{{ leadTitle }}<span class="stars">*</span></div>
  <div class="people-container">
    <div class="people" *ngIf="!addingLead">
      <app-azure-badge *ngFor="let lead of leadUser"
                       [displayName]="true"
                       [userOid]="lead.user_oid">
      </app-azure-badge>
      <button *ngIf="leadUser.length > 0" (click)="addingLead = true;"
              class="icon transparent" icon="pencil-03" qm></button>
      <button
              *ngIf="leadUser.length === 0"
              (click)="addingLead = true"
              class="secondary icon-left"
              [icon]="'plus-01'"
              qm>
        Add a person
      </button>
    </div>
    <qmtaglist *ngIf="addingLead"
               [allowAny]="false"
               [itemTemplate]="tagListTemplate"
               [tagToString]="getTagListToStringFunction()"
               [tagToId]="getTagToIdFunction()"
               (tagListChange)="assignRole($event, 'OpportunityLead')"
               [objectLists]="leadUser"
               [getPossibleTagObjects]="getPossibleTagObjectFunction">
      <ng-template #tagListTemplate let-element>
        <div class="people-finder">
          <app-azure-badge [userOid]="element.user_oid" [displayName]="false" class="hidebackground">
          </app-azure-badge>
          <div class="people-finder-texts">
            <div class="body3">{{ element.user_name }}</div>
            <div class="caption">{{ element.email }}</div>
          </div>
        </div>
      </ng-template>
    </qmtaglist>
    <button *ngIf="addingLead" (click)="changeLead()" qm
            style="margin-top: 16px" [disabled]="leadUser.length > 1">
      Confirm
    </button>
  </div>
</div>


<div class="body2" *ngIf="contributorTitle">{{ contributorTitle }}</div>
<div class="people-container">
  <div class="people" *ngIf="!addingContributor && showContributorsBadge">
    <app-azure-badge *ngFor="let lead of contributorUsers"
                     [displayName]="true"
                     [userOid]="lead.user_oid">
    </app-azure-badge>
    <button
            *ngIf="!addingContributor"
            (click)="openComponent()"
            class="secondary icon-left"
            [icon]="'plus-01'"
            qm>
      Add a contributor
    </button>
  </div>
  <qmtaglist *ngIf="addingContributor"
             [allowAny]="false"
             [itemTemplate]="tagListTemplate"
             [tagToString]="getTagListToStringFunction()"
             [tagToId]="getTagToIdFunction()"
             (tagListChange)="assignRole($event, 'Contributors')"
             [objectLists]="contributorUsers"
             [getPossibleTagObjects]="getPossibleTagObjectFunction">
    <ng-template #tagListTemplate let-element>
      <div class="people-finder">
        <app-azure-badge [userOid]="element.user_oid" [displayName]="false" class="hidebackground">
        </app-azure-badge>
        <div class="people-finder-texts">
          <div class="body3">{{ element.user_name }}</div>
          <div class="caption">{{ element.email }}</div>
        </div>
      </div>
    </ng-template>
  </qmtaglist>
  <button *ngIf="addingContributor" (click)="changeContributors()" qm
          style="margin-top: 16px">
    Confirm
  </button>
</div>
